import Linkify from "react-linkify";
import punycode from "punycode";
import React, { useCallback } from "react";
import { LuxLink } from "./LuxLink";

export const LinkedText = ({
  text,
  className,
  HTMLTag,
  onClick,
}: {
  text: string;
  className?: string;
  HTMLTag?: keyof JSX.IntrinsicElements;
  onClick?: () => unknown;
}) => {
  HTMLTag = HTMLTag || "div";

  // See https://github.com/tasti/react-linkify/issues/84
  const componentDecorator = useCallback(
    (href: string, text: string, key: number) => (
      <LuxLink href={href} key={key} onClick={onClick}>
        {punycode.toASCII(text)}
      </LuxLink>
    ),
    [onClick]
  );

  return (
    <Linkify componentDecorator={componentDecorator}>
      <HTMLTag className={className}>{text}</HTMLTag>
    </Linkify>
  );
};
