import tlds from "tlds";

export const TLD_SET = new Set([...tlds, "glow"]);

export const isValidUrl = (url: string) => {
  if (!url) {
    return false;
  }

  try {
    const urlObj = new URL(url);

    const parts = urlObj.hostname.split(".");
    if (parts.length < 2) {
      return false;
    }

    const lastPart = parts[parts.length - 1];
    return TLD_SET.has(lastPart);
  } catch (e) {
    return false;
  }
};
