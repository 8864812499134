/**
 * 12345.6789 → 12,345
 * .00001 → .00001
 */
import { TString } from "./data-types";

const getNumDecimals = (num: number) => {
  const abs = Math.abs(num);

  if (abs >= 1000) {
    return 0;
  }
  if (abs >= 100) {
    return 1;
  }
  if (abs >= 10) {
    return 2;
  }
  if (abs >= 1) {
    return 3;
  }
  if (abs >= 1) {
    return 4;
  }
  if (abs >= 0.01) {
    return 5;
  }
  if (abs >= 0.001) {
    return 6;
  }

  return 7;
};

export const formatNumber = ({
  num: _num,
  numDecimals,
  decimalMode = "max",
  abbreviated = false,
  locale,
}: {
  num: number | bigint | string;
  numDecimals?: number;
  decimalMode?: "max" | "always";
  abbreviated?: boolean | undefined;
  locale?: string;
}): TString => {
  let num: number;
  if (typeof _num === "bigint") {
    num = Number(_num);
  } else if (typeof _num === "string") {
    num = parseFloat(_num);
  } else {
    num = Number(_num);
  }

  numDecimals ??= getNumDecimals(Number(num));

  return new Intl.NumberFormat(locale, {
    maximumFractionDigits: numDecimals,
    minimumFractionDigits: decimalMode === "always" ? numDecimals : undefined,
    notation: abbreviated === true ? "compact" : undefined,
  }).format(num) as TString;
};

export const formatUsd = ({
  usd,
  abbreviated = false,
}: {
  usd: number | bigint | string;
  abbreviated?: boolean | undefined;
}): TString => {
  const num = Number(usd);

  return Intl.NumberFormat(undefined, {
    style: "currency",
    currency: "USD",
    notation: abbreviated === true ? "compact" : undefined,
  }).format(num) as TString;
};
