import { DateTime } from "luxon";
import { z } from "zod";
import { formatNumber } from "./number-formatter";

export const ETHEREUM_ADDRESS_REGEX = /^0x[0-9a-fA-F]{40}$/;

// https://stackoverflow.com/questions/72772567/how-long-ethereum-hash-length-block-transaction-address
export const ETHEREUM_HASH_REGEX = /^0x[0-9a-fA-F]{64}$/;

export namespace Ethereum {
  export enum WalletType {
    CoinbaseWallet = "Coinbase Wallet",
    Metamask = "Metamask",
    WalletConnect = "WalletConnect",
  }

  export enum Chain {
    Homestead = 1, // Mainnet chain
    Goerli = 5, // Testnet for dev
  }
  export const ChainZ = z.nativeEnum(Chain);

  export const ETH_DECIMALS = 18;
  export const ETH_SYMBOL = "ETH";

  export const AddressZ = z.string().regex(ETHEREUM_ADDRESS_REGEX);
  export type Address = string;
  // Number encoded as a hex string
  export type HexValue = string;

  export const HashZ = z.string().regex(ETHEREUM_HASH_REGEX);
  export type Hash = string;

  export const abbreviateHash = (hash: Hash) => {
    if (hash.length <= 10) {
      return hash;
    }
    return `${hash.slice(0, 5)}…${hash.slice(-4)}`;
  };

  export const abbreviateAddress = (address: string) => {
    if (address.length <= 10) {
      return address;
    }
    return `${address.slice(0, 5)}…${address.slice(-4)}`;
  };

  export namespace message {
    export const signIn = ({
      appName,
      domain,
      address,
      nonce,
      requestedAt,
    }: {
      appName: string | null;
      domain: string;
      address: Address;
      nonce: number;
      chain?: "Solana" | "Ethereum";
      requestedAt: DateTime;
    }): string => {
      const message = `${
        appName ?? domain
      } would like you to sign in with your Ethereum account:
      ${address}

      Domain: ${domain}
      Requested At: ${requestedAt.toUTC().toISO()}
      Nonce: ${nonce}`;

      return message
        .split("\n")
        .map((line) => line.trim())
        .join("\n");
    };

    export const parseSignIn = ({
      message,
    }: {
      message: string;
    }): {
      appName: string;
      domain: string;
      address: Ethereum.Address;
      nonce: string;
      requestedAt: DateTime;
    } => {
      const regexStr =
        `^(?<appName>.{0,100}) would like you to sign in with your Ethereum account:
        (?<address>0x[0-9a-fA-F]{40})

        Domain: (?<domain>[A-Za-z0-9.\\-]+)
        Requested At: (?<requestedAt>.+)
        Nonce: (?<nonce>[A-Za-z0-9\-\.]+)$`
          .split("\n")
          .map((s) => s.trim())
          .join("\n");

      const regex = new RegExp(regexStr);

      const match = message.match(regex);

      if (!match || !match.groups) {
        throw new Error("Invalid message format.");
      }

      const {
        appName,
        domain,
        address,
        nonce: _nonce,
        requestedAt: _requestedAt,
      } = match.groups;
      const nonce = _nonce;
      const requestedAt = DateTime.fromISO(_requestedAt);

      return {
        appName,
        domain,
        address,
        nonce,
        requestedAt,
      };
    };
  }

  // Used for ERC20 contracts
  export type ContractToBalance = {
    [contractAddress: Ethereum.Address]: number;
  };

  /**
   * While the ERC721 token represents ownership of a non-fungible asset,
   * this represents the account balance of a specific ERC-20 token at a
   * point in time.
   */
  export const Erc20ContractInfoZ = z.object({
    type: z.literal("erc-20"),
    contract_name: z.string().nullable(),
    contract_address: AddressZ,
    decimals: z.number(), // If we want to show the min token amount, we must have decimals
    symbol: z.string().nullable(),
    image: z.string().nullable().optional(),
  });
  export type Erc20ContractInfo = z.infer<typeof Erc20ContractInfoZ>;
  export const Erc20TokenBalanceZ = Erc20ContractInfoZ.extend({
    owner_address: AddressZ,
    owner_balance: z.string(),
  });
  export type Erc20TokenBalance = z.infer<typeof Erc20TokenBalanceZ>;

  export const Erc721ContractInfoZ = z.object({
    type: z.literal("erc-721"),
    contract_name: z.string(),
    contract_address: AddressZ,
  });
  export type Erc721ContractInfo = z.infer<typeof Erc721ContractInfoZ>;
  export const Erc721TokenZ = Erc721ContractInfoZ.extend({
    owner_address: AddressZ,
    token_id: z.string().regex(/^\d+$/),
    token_name: z.string().nullable(),

    image_url: z.string().url().nullable(),
    external_url: z.string().url().nullable(),
    opensea_url: z.string().url(),
  });
  export type Erc721Token = z.infer<typeof Erc721TokenZ>;

  const TokenZ = z.union([Erc20TokenBalanceZ, Erc721TokenZ]);
  export type Token = z.infer<typeof TokenZ>;

  export const TokenRequirementZ = z.union([
    z
      .object({
        chain: z.literal("ethereum"),
        type: z.literal("erc-721"),
        contract: Erc721ContractInfoZ,
        min_token_id: z.string().regex(/^\d+$/).nullable().optional(),
        max_token_id: z.string().regex(/^\d+$/).nullable().optional(),
      })
      .refine((val) => {
        // Prevent the range (min, max) from being invalid
        if (val.min_token_id == null || val.max_token_id == null) {
          return true;
        }
        return BigInt(val.min_token_id) <= BigInt(val.max_token_id);
      }),
    z.object({
      chain: z.literal("ethereum"),
      type: z.literal("erc-20"),
      contract: Erc20ContractInfoZ,
      // We use a string since this amount could be too large for a JS integer.
      // We restrict this to only integers.
      min_token_balance: z.string().regex(/^\d+$/).nullable().optional(),
    }),
  ]);
  export type TokenRequirement = z.infer<typeof TokenRequirementZ>;

  export const tokenPassesRequirement = ({
    token,
    filter,
  }: {
    token: Token;
    filter: TokenRequirement;
  }): boolean => {
    if (token.type !== filter.contract.type) {
      return false;
    }

    if (token.type === "erc-721" && filter.type === "erc-721") {
      if (
        token.contract_address.toLowerCase() !==
        filter.contract.contract_address.toLowerCase()
      ) {
        return false;
      }

      if (
        filter.min_token_id &&
        BigInt(filter.min_token_id) > BigInt(token.token_id)
      ) {
        return false;
      }

      if (
        filter.max_token_id &&
        BigInt(filter.max_token_id) < BigInt(token.token_id)
      ) {
        return false;
      }

      return true;
    }

    if (token.type === "erc-20" && filter.type === "erc-20") {
      if (
        token.contract_address.toLowerCase() !==
        filter.contract.contract_address.toLowerCase()
      ) {
        return false;
      }

      if (filter.min_token_balance) {
        const min_token_num = Erc20TokenAmount.toBigInt({
          amount: filter.min_token_balance,
        });

        // If the balance is bigger than the min amount, the filter passes
        return min_token_num <= BigInt(token.owner_balance);
      }

      return true;
    }

    return false;
  };

  export const sanitizeTokenFilters = ({
    filters,
  }: {
    filters: any[];
  }): TokenRequirement[] => {
    const filtersWithNull: Array<Ethereum.TokenRequirement | null> =
      filters.map((filter) => {
        const parsed = Ethereum.TokenRequirementZ.safeParse(filter);
        if (parsed.success) {
          return parsed.data;
        }
        return null;
      });

    return filtersWithNull.filter((obj) => obj !== null) as TokenRequirement[];
  };

  export namespace Erc20TokenAmount {
    // We display with decimals, but we store as an integer.
    export const toDisplay = ({
      amount,
      decimals,
      symbol,
    }: {
      amount: string | bigint;
      decimals: number;
      symbol?: string | null;
    }): string => {
      const num = formatNumber({
        num: Number(amount) / Math.pow(10, decimals),
      });

      if (!symbol) {
        return num;
      }

      return `${num} ${symbol}`;
    };

    export const toBigInt = ({ amount }: { amount: string }): bigint => {
      return BigInt(amount);
    };

    export const toStorage = ({
      display,
      decimals,
    }: {
      display: string;
      decimals: number;
    }): string => {
      return (Number(display) * Math.pow(10, decimals)).toFixed(0);
    };
  }
}
